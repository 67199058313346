body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #eeeeee;
    font-family: 'Chakra Petch', sans-serif;
}

.container {
    display: flex;
    position: absolute;
}

.hero-container{
    width: 100%;
    height: 100%;
    position: fixed;


}
.hero {
    /* Set a specific height */
    width: 100%;

    /* Position and center the image to scale nicely on all screens */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    background-position: center center;
    background-attachment: fixed;
    width: auto;
    opacity: 0.25;
}

.prompt-container{
    width: 100%;
    height: 100%;
    position: fixed;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
}
.prompt{
    text-align: center;

    width: 400px;
    height: 150px;
    
    line-height: 10px;
    margin: 0;
    padding: 0;

    position: relative;
    background-color: #fafafe;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}
.prompt h1 { /* Mercenary camp */
    color:#555555
}
.prompt h5 { /* Coders for hire */
    font-size: 20px;
    margin-top: 5px;
    height: 0px;
    color:#bbb
}
.prompt-container-input{
    margin: 0;
    padding: 0;
    width: 80%;
    height: 40px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    background-color: #fafafe;
    color: gray;
    border: none;
	font: 5ch droid sans mono, consolas, monospace;

}
.prompt-container-input:focus{
    color: #555555;
    border: none;
    outline: none;
}
.loading-container{
    height: 0%;
    padding-top: 0px;
    margin-top: 10px;

    color:black;
}

@keyframes enterButton {
    0%   {transform: scale(1);}
    70%   {transform: scale(1);}
    80%   {transform: scale(1.04);}
    90%   {transform: scale(0.96);}
    100%   {transform: scale(1);}
}

.enter-button{
    animation-name: enterButton;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 4px;
    border: none;
    outline: none;
    width: 300px;
    padding: 0 15px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    margin: 25px 0 10px;
    color: black;
    background: linear-gradient(#fefefe, #ddd);
    box-shadow: 0 2px 12px 1px #333;
    opacity: 0.9;
    font-family: 'Chakra Petch', sans-serif;
}
.enter-button:hover{
    opacity: 1;
    box-shadow: 0 2px 2px -3px #111;
}

.button {
    font: bold 11px Arial;
    text-decoration: none;
    background-color: #EEEEEE;
    color: #333333;
    padding: 2px 6px 2px 6px;
    border-top: 1px solid #CCCCCC;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #CCCCCC;
  }